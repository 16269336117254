import { RoleFullDto } from '../../authentication/model/role.model';
import { UntypedFormGroup } from '@angular/forms';


export const getContextLabelForRole = (roles: RoleFullDto[], role: string): string | null => {
  const selectedRole = roles.find(r => r.code === role);
  return selectedRole && selectedRole.context ? selectedRole.context.name : null;
};

export const getLabelForRole = (roles: RoleFullDto[], role: string): string | null => {
  const selectedRole = roles.find(r => r.code === role);
  return selectedRole ? selectedRole.name : null;
};

export const isRoleSelected = (userForm: UntypedFormGroup, role: string): boolean => {
  const selectedRoles = userForm.get('role')?.value || [];
  return selectedRoles.includes(role);
};

export const toggleRole = (userForm: UntypedFormGroup, role: string): void => {
  const selectedRoles = userForm.get('role')?.value || [];
  if (selectedRoles.includes(role)) {
    userForm.patchValue({
      role: selectedRoles.filter(r => r !== role),
    });
  } else {
    userForm.patchValue({
      role: [...selectedRoles, role],
    });
  }
};


import { notifyInfo, notifyWarn } from 'src/app/model/notification.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  changeUserStatus,
  changeUserStatusFailure,
  changeUserStatusSuccess,
  createNewUser,
  createNewUserFailure,
  createNewUserSuccess,
  fetchAllRoles,
  fetchAllRolesFailure,
  fetchAllRolesSuccess,
  fetchAllUsers,
  fetchAllUsersFailure,
  fetchAllUsersSuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from './admin.action';
import { UserAdminService } from '../service/user-admin.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminEffects {
  createNewUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewUser),
      mergeMap(({ newUser }) =>
        this.adminService.createNewUser(newUser).pipe(
          map(() => createNewUserSuccess(notifyInfo('notification.info.admin.userCreated', true))),
          catchError(error => of(createNewUserFailure({ error, ...notifyWarn(error.error.message) })))
        )
      )
    )
  );

  createNewUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewUserSuccess),
        tap(() => {
          this.router.navigate(['/cockpit']);
        })
      ),
    { dispatch: false }
  );

  updateUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      mergeMap(({ user }) =>
        this.adminService.updateUser(user).pipe(
          map(() => updateUserSuccess()),
          catchError(error => of(updateUserFailure({ error, ...notifyWarn(error.error.message) })))
        )
      )
    )
  );

  updateUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserSuccess),
        tap(() => this.router.navigate(['/cockpit']))
      ),
    { dispatch: false }
  );

  fetchAllUsersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllUsers),
      mergeMap(() =>
        this.adminService.fetchAllUsers().pipe(
          map(users => fetchAllUsersSuccess({ users })),
          catchError(err => of(fetchAllUsersFailure(err)))
        )
      )
    )
  );

  changeUserStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeUserStatus),
      mergeMap(({ userId, enabled }) =>
        this.adminService.setUserStatus(userId, enabled).pipe(
          map(() => changeUserStatusSuccess({ userId, enabled })),
          catchError(error => of(changeUserStatusFailure({ error, ...notifyWarn(error.error.message) })))
        )
      )
    )
  );

  fetchAllRolesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllRoles),
      mergeMap(() =>
        this.adminService.fetchAllRoles().pipe(
          map(roles => fetchAllRolesSuccess({ roles })),
          catchError(err => of(fetchAllRolesFailure(err)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private adminService: UserAdminService,
    private router: Router,
  ) {}
}

import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import UserState from './user.reducer';
import { role } from '../capability';
import { ROLE } from '../model/role.model';
import { User } from '../model/user.model';

export const getUserState = (state: State) => state.user;

export const selectUser = createSelector(getUserState, (state: UserState) => state?.user);

export const selectUserId = createSelector(selectUser, (user?: User) => user?.id);

export const selectUserRole = createSelector(selectUser, (user?: User) => user?.role);

export const selectRedirectForNonAdminUsers = createSelector(selectUser, (user?: User) => {
  if (user?.role !== ROLE.admin) {
    return '/cockpit';
  }
  return undefined;
});

export const selectIsSCPI = createSelector(selectUser, user => user !== undefined && role(user.role).isSCPI());

export const selectIsECABOrAbove = createSelector(selectUser, user => user !== undefined && role(user.role).isEcabOrAbove());

export const selectIsExpertOrAbove = createSelector(selectUser, user => user !== undefined && role(user.role).isExpertOrAbove());

export const selectIsAdmin = createSelector(selectUser, user => user !== undefined && role(user.role).isAdmin());

export const selectIsReadOnly = createSelector(selectUser, user => user !== undefined && role(user.role).isReadOnly());

export const selectIsSpecialistOrAbove = createSelector(selectUser, user => user !== undefined && role(user.role).isSpecialistOrAbove());

export const selectIsSecretaryOrAbove = createSelector(selectUser, user => user !== undefined && role(user.role).isSecretaryOrAbove());

export const selectIsCECB = createSelector(selectUser, user => user !== undefined && role(user.role).isCECB());

export const selectIsSCPIOrAbove = createSelector(selectUser, user => user !== undefined && role(user.role).isSCPIOrAbove());

export const selectIsSecretary = createSelector(selectUser, user => user !== undefined && role(user.role).isSecretary());

export const selectUserAccreditations = createSelector(selectUser, user => user?.accreditations);

export const selectIsGisTokenRegistered = createSelector(getUserState, (state: UserState) =>
  state !== undefined && state.registeredGisToken
);

export const selectConfiguration = createSelector(getUserState, (state: UserState) => state.configuration);

export const selectUserReportUrl = createSelector(selectConfiguration, configuration => configuration?.userReportUrl);

export const selectMotd = createSelector(getUserState, (state: UserState) => state?.motd);

export const selectHasPolicy = (policyCode: string) => createSelector(selectUser, user =>
  user !== undefined && user.policies.some(policy => policy === policyCode));

export const selectHasRole = (roleCode: string) => createSelector(selectUser, user =>
  user !== undefined && user.roles.some(r => r === roleCode));

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ROLE, RoleFullDto } from '../../../authentication/model/role.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MunicipalitySummary } from '../../../building/model/municipality.model';
import { Store } from '@ngrx/store';
import { selectSortedMunicipalities } from '../../../building/store/reference-data.selector';
import { UserUpdate } from '../../../admin/model/user-creation.model';
import { updateUser } from '../../../admin/store/admin.action';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/authentication/model/user.model';
import { selectUser } from 'src/app/authentication/store/user.selector';
import { role } from 'src/app/authentication/capability';
import { selectAllRoles } from '../../../admin/store/admin.selector';
import { getContextLabelForRole, getLabelForRole, isRoleSelected, toggleRole } from '../user-tools';

@Component({
  selector: 'sibat-edit-user',
  template: `
    <form [formGroup]="userForm" *transloco="let t; read: 'cockpit'">
      <div fxLayout="row">
        <div class="general-info" fxLayout="column">
          <div class="form-field mat-form-field-wrapper">
            <mat-slide-toggle formControlName="enabled" color="warn">{{ t('enabled') }}</mat-slide-toggle>
          </div>

          <mat-form-field>
            <mat-label>{{ t('lastName') }}</mat-label>
            <input matInput formControlName="lastName" required />
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <div>
            <mat-form-field>
              <mat-label>{{ t('firstName') }}</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('email') }}</mat-label>
              <input matInput formControlName="email" required />
              <mat-error>{{ t('requiredValidEmail') }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('phoneNumber') }}</mat-label>
              <input matInput formControlName="phoneNumber" />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>{{ t('language') }}</mat-label>
              <mat-select formControlName="language" required>
                <mat-option value="fr">{{ t('french') }}</mat-option>
                <mat-option value="de">{{ t('german') }}</mat-option>
              </mat-select>
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <sibat-primary-button (clicked)="onSubmit()" [label]="'cockpit.submit' | transloco"></sibat-primary-button>
        </div>
        <div fxLayout="column" class="role-context">
          <div>
            <mat-form-field class="role">
              <mat-label>{{ t('role') }}</mat-label>
              <mat-chip-list>
                <mat-chip
                  *ngFor="let role of (roles$ | async)"
                  selectable
                  [class.selected]="userForm.get('role')?.value.includes(role.code)"
                  (click)="toggleRole(role.code)"
                >
                  <mat-icon matChipAvatar *ngIf="isRoleSelected(role.code)">check</mat-icon>
                  {{ role.name }}
                </mat-chip>
              </mat-chip-list>
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>

          <div *ngFor="let role of userForm.get('role')?.value">
            <ng-container *ngIf="(roles$ | async) as roles">
              <div *ngIf="getContextLabelForRole(role) !== 'Commune'">
                <mat-form-field class="context">
                  <mat-label>Contexte pour <b>{{ getLabelForRole(role) }}</b></mat-label>
                  <mat-select formControlName="accreditation" multiple panelClass="list-540px" disabled>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="getContextLabelForRole(role) === 'Commune'">
                <mat-form-field class="context">
                  <mat-label>{{ t('municipalities') }} : <b>{{ getLabelForRole(role) }}</b></mat-label>
                  <mat-select formControlName="accreditation" multiple panelClass="list-540px">
                    <mat-option *ngFor="let municipality of this.municipalities$ | async"
                                value="{{ municipality.nr }}">{{
                        municipality.name
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
        <!---***********************************************-->
      </div>
    </form>
  `,
  styleUrls: ['edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  //roles = Object.values(ROLE);
  userForm: UntypedFormGroup;
  municipalities$: Observable<MunicipalitySummary[]>;
  user?: User;
  role = new UntypedFormControl({value: [], disabled: true}, {validators: [Validators.required], updateOn: 'change'});
  isAccreditationBased = false;
  roles$: Observable<RoleFullDto[]>;

  private subscription = new Subscription();

  constructor(private store: Store, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute) {
    this.subscription.add(
      this.role.valueChanges.subscribe(value => {
        this.isAccreditationBased = value !== undefined && role(value).isAccreditationBased();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.roles$ = this.store.select(selectAllRoles);
    const enabled = new UntypedFormControl({value: '', disabled: true}, Validators.required);

    this.userForm = this.formBuilder.group({
      lastName: ['', {validators: [Validators.required], updateOn: 'change'}],
      firstName: ['', {validators: [Validators.required], updateOn: 'change'}],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      phoneNumber: ['', {updateOn: 'change'}],
      role: this.role,
      language: ['', {validators: [Validators.required], updateOn: 'change'}],
      accreditation: ['', {updateOn: 'change'}],
      enabled,
    });

    this.municipalities$ = this.store.select(selectSortedMunicipalities);

    this.subscription.add(
      this.route.data.subscribe(data => {
        this.user = data.user;

        this.userForm.setValue({
          lastName: this.user?.lastName,
          firstName: this.user?.firstName,
          email: this.user?.email,
          phoneNumber: this.user?.phoneNumber,
          role: this.user?.roles,
          language: this.user?.language,
          accreditation: this.user?.accreditations.map(x => x.nr.toString()),
          enabled: this.user?.enabled,
        });
      })
    );

    this.subscription.add(
      this.store.select(selectUser).subscribe(user => {
        if (user !== undefined && user.id === this.user?.id) {
          enabled.disable();
          this.role.disable();
        } else {
          enabled.enable();
          this.role.enable();
        }
      })
    );
  }

  isRoleSelected(selectedRole: string): boolean {
    return isRoleSelected(this.userForm, selectedRole);
  }

  getContextLabelForRole(selectedRole: string): string | null {
    let contextLabel: string | null = null;
    this.roles$.subscribe((roles) => {
      contextLabel = getContextLabelForRole(roles, selectedRole);
    });
    return contextLabel;
  }

  getLabelForRole(selectedRole: string): string | null {
    let label: string | null = null;
    this.roles$.subscribe((roles) => {
      label = getLabelForRole(roles, selectedRole);
    });
    return label;
  }

  toggleRole(changedRole: string) {
    toggleRole(this.userForm, changedRole);
  }

  onSubmit() {
    if (!this.user) {
      return;
    }

    const accreditation = this.userForm.get('accreditation')?.value;
    const user: UserUpdate = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      phoneNumber: this.userForm.get('phoneNumber')?.value,
      role: this.user.role,
      language: this.userForm.get('language')?.value,
      id: this.user.id,
      enabled: this.userForm.get('enabled')?.value,
      roles: this.userForm.get('role')?.value
    };
    if (accreditation.length > 0) {
      user.accreditations = accreditation;
    }
    this.store.dispatch(updateUser({user}));
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectSortedMunicipalities } from '../../../building/store/reference-data.selector';
import { MunicipalitySummary } from '../../../building/model/municipality.model';
import { createNewUser } from '../../../admin/store/admin.action';
import { Role, ROLE, RoleFullDto } from '../../../authentication/model/role.model';
import { UserCreation } from '../../../admin/model/user-creation.model';
import { selectAllRoles } from '../../../admin/store/admin.selector';
import { getContextLabelForRole, isRoleSelected, toggleRole, getLabelForRole } from '../user-tools';

@Component({
  selector: 'sibat-new-user',
  template: `
    <form [formGroup]="userForm" *transloco="let t; read: 'cockpit'">
      <div fxLayout="row">
        <div fxLayout="column" class="general-info">
          <mat-form-field>
            <mat-label>{{ t('lastName') }}</mat-label>
            <input matInput formControlName="lastName" required />
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <div>
            <mat-form-field>
              <mat-label>{{ t('firstName') }}</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('email') }}</mat-label>
              <input matInput formControlName="email" required />
              <mat-error>{{ t('requiredValidEmail') }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('phoneNumber') }}</mat-label>
              <input matInput formControlName="phoneNumber" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('language') }}</mat-label>
              <mat-select formControlName="language" required>
                <mat-option value="fr">{{ t('french') }}</mat-option>
                <mat-option value="de">{{ t('german') }}</mat-option>
              </mat-select>
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <sibat-primary-button (clicked)="onSubmit()" [label]="'submit' | transloco"></sibat-primary-button>
        </div>

        <div fxLayout="column" class="role-context">

          <div>
            <mat-form-field class="role">
              <mat-label>{{ t('role') }}</mat-label>
              <mat-chip-list>
                <mat-chip
                  *ngFor="let role of (roles$ | async)"
                  selectable
                  [class.selected]="userForm.get('role')?.value.includes(role.code)"
                  (click)="toggleRole(role.code)"
                >
                  <mat-icon matChipAvatar *ngIf="isRoleSelected(role.code)">check</mat-icon>
                  {{ role.name }}
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>

          <div *ngFor="let role of userForm.get('role')?.value">
            <ng-container *ngIf="(roles$ | async) as roles">
              <div *ngIf="getContextLabelForRole(role) !== 'Commune'">
                <mat-form-field class="context">
                  <mat-label>Contexte pour <b>{{ getLabelForRole(role) }}</b></mat-label>
                  <mat-select formControlName="municipalities" multiple panelClass="list-540px" disabled>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="getContextLabelForRole(role) === 'Commune'">
                <mat-form-field class="context">
                  <mat-label>{{ t('municipalities') }} : <b>{{ getLabelForRole(role) }}</b></mat-label>
                  <mat-select formControlName="municipalities" multiple panelClass="list-540px">
                    <mat-option *ngFor="let municipality of this.municipalities$ | async"
                                value="{{ municipality.nr }}">{{
                        municipality.name
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <!---***********************************************-->
        </div>
      </div>
    </form>
  `,
  styleUrls: ['new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  userForm: UntypedFormGroup;
  municipalities$: Observable<MunicipalitySummary[]>;
  roles$: Observable<RoleFullDto[]>;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.municipalities$ = this.store.select(selectSortedMunicipalities);
    this.roles$ = this.store.select(selectAllRoles);
    this.userForm = this.formBuilder.group({
      lastName: ['', { validators: [Validators.required], updateOn: 'change' }],
      firstName: ['', { validators: [Validators.required], updateOn: 'change' }],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      phoneNumber: ['', { updateOn: 'change' }],
      role: [[], { validators: [Validators.required], updateOn: 'change' }],
      language: ['', { validators: [Validators.required], updateOn: 'change' }],
      municipalities: ['', { updateOn: 'change' }],
    });
  }

  isRoleSelected(selectedRole: string): boolean {
    return isRoleSelected(this.userForm, selectedRole);
  }

  getLabelForRole(selectedRole: string): string | null {
    let label: string | null = null;
    this.roles$.subscribe((roles) => {
      label = getLabelForRole(roles, selectedRole);
    });
    return label;
  }

  getContextLabelForRole(selectedRole: string): string | null {
    let contextLabel: string | null = null;
    this.roles$.subscribe((roles) => {
      contextLabel = getContextLabelForRole(roles, selectedRole);
    });
    return contextLabel;
  }

  toggleRole(changedRole: string) {
    toggleRole(this.userForm, changedRole);
  }

  resolveRole(rolesCodes: string[]): Role
  {
    const roleCode = rolesCodes[0];
    return ROLE[roleCode.replace('role_', '').toLowerCase()];
  }

  onSubmit() {
    const municipalities = this.userForm.get('municipalities')?.value;
    const newUser: UserCreation = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      phoneNumber: this.userForm.get('phoneNumber')?.value,
      role: this.resolveRole(this.userForm.get('role')?.value),
      language: this.userForm.get('language')?.value,
      roles: this.userForm.get('role')?.value
    };
    if (municipalities.length > 0) {
      newUser.accreditations = municipalities;
    }
    this.store.dispatch(createNewUser({ newUser }));
  }
}

export const ROLE = {
  readOnly: 'READ_ONLY',
  cecb: 'CECB',
  scpi: 'SCPI',
  secretary: 'SECRETARY',
  expert: 'EXPERT',
  admin: 'ADMIN',
} as const;
export type Role = typeof ROLE[keyof typeof ROLE];

export interface ContextDto {
  id: number;
  name: string;
}

export interface PolicyDto {
  name: string;
  code: string;
  enabled: boolean;
}

export interface RoleFullDto {
  name: string;
  code: string;
  context?: ContextDto;
  policies: PolicyDto[];
}
